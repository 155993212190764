import React, { Component } from 'react';
import { Router, Route, Switch } from 'react-router-dom';
// import { renderRoutes } from 'react-router-config';
import './App.scss';
import 'primereact/resources/themes/nova-light/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';

import 'pace-js';
import 'pace-js/themes/blue/pace-theme-minimal.css';

import { connect } from 'react-redux';
import { history } from './helpers';
import { alertActions } from './actions';
import { PrivateRoute } from './components';
import { rrsmService } from './services';

const loading = () => <div className="animated fadeIn pt-3 text-center">Loading...</div>;

// Containers
const DefaultLayout = React.lazy(() => import('./containers/DefaultLayout'));

// Pages
const Login = React.lazy(() => import('./views/Pages/Login/Login'));
const Register = React.lazy(() => import('./views/Pages/Register/Register'));
const Page404 = React.lazy(() => import('./views/Pages/Page404/Page404'));
const Page500 = React.lazy(() => import('./views/Pages/Page500/Page500'));
const ConfirmEmail = React.lazy(() => import('./views/Pages/ConfirmEmail/ConfirmEmail'));

class App extends Component {
  constructor(props) {
    super(props);

    const { dispatch } = this.props;
    history.listen((location, action) => {
      // clear alert on location change
      dispatch(alertActions.clear());
    });
  }

  componentDidMount() {
    setInterval(() => {
      if (null !== JSON.parse(localStorage.getItem('rrsmToken')) &&
          null !== JSON.parse(localStorage.getItem('rrsmSid')))
      {
        var rssmToken = JSON.parse(localStorage.getItem('rrsmToken')).token;
        var rssmSid = JSON.parse(localStorage.getItem('rrsmSid')).sid;

        rrsmService.restartWatchdog(rssmToken, rssmSid)
        .subscribe(response => {
          console.log(response);
        });
      }
    }, 1000 * 59) // 59 sec, watchdog timeout is 120 sec
  }

  render() {
    return (
      <Router history={history}>
          <React.Suspense fallback={loading()}>
            <Switch>
              <Route exact path="/login" name="Login Page" render={props => <Login {...props}/>} />
              <Route exact path="/register" name="Register Page" render={props => <Register {...props}/>} />
              <Route exact path="/404" name="Page 404" render={props => <Page404 {...props}/>} />
              <Route exact path="/500" name="Page 500" render={props => <Page500 {...props}/>} />
              <Route exact path="/confirm/:key/:success" name="Confirm Email" render={props => <ConfirmEmail {...props}/>} />

              <PrivateRoute path="/" name="Projects" component={DefaultLayout} />
            </Switch>
          </React.Suspense>
      </Router>
    );
  }
}

function mapStateToProps(state) {
  const { alert } = state;
  return {
      alert
  };
}

const connectedApp = connect(mapStateToProps)(App);
export { connectedApp as App };
