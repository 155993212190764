export const userConstants = {
    REGISTER_REQUEST: 'USERS_REGISTER_REQUEST',
    REGISTER_SUCCESS: 'USERS_REGISTER_SUCCESS',
    REGISTER_FAILURE: 'USERS_REGISTER_FAILURE',

    LOGIN_REQUEST: 'USERS_LOGIN_REQUEST',
    LOGIN_SUCCESS: 'USERS_LOGIN_SUCCESS',
    LOGIN_FAILURE: 'USERS_LOGIN_FAILURE',

    LOGOUT: 'USERS_LOGOUT',

    GETALLPROJECTS_REQUEST: 'USERS_GETALLPROJECTS_REQUEST',
    GETALLPROJECTS_SUCCESS: 'USERS_GETALLPROJECTS_SUCCESS',
    GETALLPROJECTS_FAILURE: 'USERS_GETALLPROJECTS_FAILURE',

    GETALLROUTINES_REQUEST: 'USERS_GETALLROUTINES_REQUEST',
    GETALLROUTINES_SUCCESS: 'USERS_GETALLROUTINES_SUCCESS',
    GETALLROUTINES_FAILURE: 'USERS_GETALLROUTINES_FAILURE',

    GETALLPOSES_REQUEST: 'USERS_GETALLPOSES_REQUEST',
    GETALLPOSES_SUCCESS: 'USERS_GETALLPOSES_SUCCESS',
    GETALLPOSES_FAILURE: 'USERS_GETALLPOSES_FAILURE',

    GETALLTOOLS_REQUEST: 'USERS_GETALLTOOLS_REQUEST',
    GETALLTOOLS_SUCCESS: 'USERS_GETALLTOOLS_SUCCESS',
    GETALLTOOLS_FAILURE: 'USERS_GETALLTOOLS_FAILURE',

    PROGRAMROBOT_REQUEST: 'USERS_PROGRAMROBOT_REQUEST',
    PROGRAMROBOT_SUCCESS: 'USERS_PROGRAMROBOT_SUCCESS',
    PROGRAMROBOT_FAILURE: 'USERS_PROGRAMROBOT_FAILURE',

    UPDATE_PASSWORD_REQUEST: 'USER_UPDATE_PASSWORD_REQUEST',
    UPDATE_PASSWORD_SUCCESS: 'USER_UPDATE_PASSWORD_SUCCESS',
    UPDATE_PASSWORD_FAILURE: 'USER_UPDATE_PASSWORD_FAILURE',

    DELETE_REQUEST: 'USERS_DELETE_REQUEST',
    DELETE_SUCCESS: 'USERS_DELETE_SUCCESS',
    DELETE_FAILURE: 'USERS_DELETE_FAILURE',
};
