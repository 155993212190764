export const routineConstants = {
    ROUTINE_ADDNEW_REQUEST: 'ROUTINE_ADDNEW_REQUEST',
    ROUTINE_ADDNEW_SUCCESS: 'ROUTINE_ADDNEW_SUCCESS',
    ROUTINE_ADDNEW_FAILURE: 'ROUTINE_ADDNEW_FAILURE',

    ROUTINE_UPDATE_REQUEST: 'ROUTINE_UPDATE_REQUEST',
    ROUTINE_UPDATE_SUCCESS: 'ROUTINE_UPDATE_SUCCESS',
    ROUTINE_UPDATE_FAILURE: 'ROUTINE_UPDATE_FAILURE',
    
    ROUTINE_DELETE_REQUEST: 'ROUTINE_DELETE_REQUEST',
    ROUTINE_DELETE_SUCCESS: 'ROUTINE_DELETE_SUCCESS',
    ROUTINE_DELETE_FAILURE: 'ROUTINE_DELETE_FAILURE',

    ROUTINE_SELECT: 'ROUTINE_SELECT',

    ROUTINE_GET_REQUEST: 'ROUTINE_GET_REQUEST',
    ROUTINE_GET_SUCCESS: 'ROUTINE_GET_SUCCESS',
    ROUTINE_GET_FAILURE: 'ROUTINE_GET_FAILURE',
};
