import { userConstants } from '../constants';

export function user(state = {}, action) {
    switch (action.type) {
        case userConstants.GETALLPROJECTS_REQUEST:
            return {
                loading: true
            };
        case userConstants.GETALLPROJECTS_SUCCESS:
            return {
                projects: action.projects
            };
        case userConstants.GETALLPROJECTS_FAILURE:
            return {
                error: action.error
            };

        case userConstants.GETALLROUTINES_REQUEST:
            return {
                loading: true
            };
        case userConstants.GETALLROUTINES_SUCCESS:
            return {
                routines: action.routines
            };
        case userConstants.GETALLROUTINES_FAILURE:
            return {
                error: action.error
            };

        case userConstants.GETALLPOSES_REQUEST:
            return {
                loading: true
            };
        case userConstants.GETALLPOSES_SUCCESS:
            return {
                poses: action.poses
            };
        case userConstants.GETALLPOSES_FAILURE:
            return {
                error: action.error
            };

        case userConstants.GETALLTOOLS_REQUEST:
            return {
                loading: true
            };
        case userConstants.GETALLTOOLS_SUCCESS:
            return {
                tools: action.tools
            };
        case userConstants.GETALLTOOLS_FAILURE:
            return {
                error: action.error
            };

        case userConstants.UPDATE_PASSWORD_REQUEST:
            return {
                loading: true
            };
        case userConstants.UPDATE_PASSWORD_SUCCESS:
            return {
                updatedUser: action
            };
        case userConstants.UPDATE_PASSWORD_FAILURE:
            return {
                error: action.error
            };


        case userConstants.DELETE_REQUEST:
            return {
                loading: true
            };
        case userConstants.DELETE_SUCCESS:
            return {
                deletedUser: action
            };
        case userConstants.DELETE_FAILURE:
            return {
                error: action.error
            };
        default:
            return state
    }
}
