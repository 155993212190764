import { Observable } from 'rxjs';
import io from 'socket.io-client';
import { capsuleConstants } from '../constants';
import { capsuleService } from '../services';
import { alertActions } from './';
import { store } from '../helpers';

const socket = io(process.env.BACKENDURL, { autoConnect: false, reconnectionAttempts: 5 });

export const capsuleActions = {
    getCurrentState,
    sendProgram,
    connectWebSocket,
    sendJoggingProgramOnWebSocket,
    disconnectWebSocket
};

function getCurrentState(sid) {
    return dispatch => {

        return Observable.create((observer) => {
            dispatch(request({ sid }));

            capsuleService.getCurrentState(sid)
            .subscribe({
                next: response => {
                    dispatch(success(sid));
                    dispatch(alertActions.success(response.message));
                    observer.next(response)
                    observer.complete()
                },
                error: err => {
                    console.log(err);
                    dispatch(failure(err.message));
                    dispatch(alertActions.error(err.message));
                    observer.error(err)
                }
            })
        })
    };

    function request(capsule) { return { type: capsuleConstants.CAPSULE_GETSTATE_REQUEST, capsule } }
    function success(capsule) { return { type: capsuleConstants.CAPSULE_GETSTATE_SUCCESS, capsule } }
    function failure(error) { return { type: capsuleConstants.CAPSULE_GETSTATE_FAILURE, error } }
}

function sendProgram(sid, code) {
    return dispatch => {

        return Observable.create((observer) => {
            dispatch(request({ sid }));

            capsuleService.sendProgram(sid, code)
            .subscribe({
                next: response => {
                    dispatch(success(sid));
                    dispatch(alertActions.success(response.message));
                    observer.next(response)
                    observer.complete()
                },
                error: err => {
                    console.log(err);
                    dispatch(failure(err.message));
                    dispatch(alertActions.error(err.message));
                    observer.error(err)
                }
            })
        })
    };

    function request(capsule) { return { type: capsuleConstants.CAPSULE_SENDPROGRAM_REQUEST, capsule } }
    function success(capsule) { return { type: capsuleConstants.CAPSULE_SENDPROGRAM_SUCCESS, capsule } }
    function failure(error) { return { type: capsuleConstants.CAPSULE_SENDPROGRAM_FAILURE, error } }
}

function connectWebSocket(_sid) {
    socket.connect();
    socket.on('connect', () => {
        console.log(`Connected with id ${socket.id}`);
    });
    socket.emit('connectToCapsule', { sid: _sid });
    socket.on('robotstate', state => {
        store.dispatch(success(state));
    })

    function success(state) { return { type: capsuleConstants.CAPSULE_GETSTATE_SUCCESS, state } }
}

function sendJoggingProgramOnWebSocket(_program) {
    socket.emit('jogging', { program: _program });
}

function disconnectWebSocket() {
    socket.disconnect();
}
