import axios from 'axios';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

export const poseService = {
    addNew,
    deletePose,
    getPose,
    updatePose
}

function addNew(userId, poseName, code, poseType, description) {
    return Observable.create((observer) => {
        axios.post('/api/pose', {
            userId: userId,
            poseName: poseName,
            code: code,
            poseType: poseType,
            description: description
        })
        .then(response => {
            observer.next(response.data)
            observer.complete()
        })
        .catch(err => {
            observer.error(err)
        })
    })
    .pipe(
        tap(response => {
            if (response.success !== true) {
                throw response.message;
            }
        })
    )
}

function deletePose(poseId) {
    return Observable.create((observer) => {
        axios.delete('/api/poses/'+poseId)
        .then(response => {
            observer.next(response.data)
            observer.complete()
        })
        .catch(err => {
            observer.error(err)
        })
    })
    .pipe(
        tap(response => {
            if (response.success !== true) {
                throw response.message;
            }
        })
    )
}

function getPose(poseId) {
    return Observable.create((observer) => {
        axios.get('/api/poses/'+poseId)
        .then(response => {
            observer.next(response.data)
            observer.complete()
        })
        .catch(err => {
            observer.error(err)
        })
    })
    .pipe(
        tap(response => {
            if (response.success !== true) {
                throw response.message;
            }
        })
    )
}

/**
 * @param {JSON object} pose - properties: poseId, poseName, poseDescription
 */
function updatePose(pose) {
    return Observable.create((observer) => {
        axios.put('/api/pose', pose)
        .then(response => {
            observer.next(response.data)
            observer.complete()
        })
        .catch(err => {
            observer.error(err)
        })
    })
    .pipe(
        tap(response => {
            if (response.success !== true) {
                throw response.message;
            }
        })
    )
}
