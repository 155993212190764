import axios from 'axios';
import { Observable } from 'rxjs';
import { projectService, routineService } from '../services';

export const blocklyService = {
    sendProgramTest,
    injectBlockly,
    setCurrentProject,
    setRoutineList,
    setPoseList,
    setToolList,
    getWorkspaceCode,
    getWorkspaceXmlText,
    undoWorkspaceAction,
    redoWorkspaceAction,
    convertBlockToCode,
    clearWorkspace,
    loadWorkspace,
    createNewRoutineBlock,
    createNewPoseBlock,
    createNewToolBlock,
    refreshToolboxSelection
}

const blocklyInstance = window.Blockly;
var blocklyWorkspace = null;
var hBlocklyWorkspace = null;
var workspaceCode = null;
var workspaceXML = null;
var workspaceXMLText = null;
var currentProject = null;
var routineList = [];
var poseList = [];
var toolList = [];

function sendProgramTest(code) {
    axios.get('/api/robot/connect')

    let res = axios.post('/api/robot/program/ur', {
        robotProgram: code,
    })
    .then(res => {
        return res.data.message;
    });

    return res;
}

function injectBlockly(_toolbox) {
  return Observable.create((observer) => {
        var options = {
            toolbox: _toolbox,
            trashcan: true,
            scrollbars: true,
            sounds: true,
            grid: {
                spacing: 20,
                length: 1,
                colour: '#ccc',
                snap: false
            },
            zoom: {
                controls: true,
                wheel: false,
                startScale: 1,
                maxScale: 3,
                minScale: 0.3,
                scaleSpeed: 1.2
            }
        };

        var blocklyDiv = document.getElementById('blocklyDiv');
        blocklyWorkspace = blocklyInstance.inject(blocklyDiv, options);

        blocklyWorkspace.addChangeListener(renderContent);
        blocklyWorkspace.addChangeListener(saveOnChange);

        blocklyWorkspace.registerToolboxCategoryCallback('ROUTINE', () => {
            return routineList;
        });

        blocklyWorkspace.registerToolboxCategoryCallback('POSE', () => {
            return poseList;
        });

        blocklyWorkspace.registerToolboxCategoryCallback('TOOL', () => {
            return toolList;
        });

        observer.next();
        observer.complete();
    });
}

function renderContent() {
    workspaceCode = blocklyInstance.UR_script.workspaceToCode(blocklyWorkspace);

    /* workspace to xml */

    workspaceXML = blocklyInstance.Xml.workspaceToDom(blocklyWorkspace);
    workspaceXMLText = blocklyInstance.Xml.domToText(workspaceXML);
}

function saveOnChange(event) {
    if (event.type === blocklyInstance.Events.CHANGE ||
        event.type === blocklyInstance.Events.CREATE ||
        event.type === blocklyInstance.Events.DELETE ||
        event.type === blocklyInstance.Events.MOVE)
    {
        let observable$ = null;
        currentProject['definition'] = workspaceXMLText;

        if (currentProject['projectName']) {
            observable$ = projectService.updateProject(currentProject)
        } else if (currentProject['routineName']) {
            observable$ = routineService.updateRoutine(currentProject)
        }
        observable$.subscribe({
            next: res => {},
            error: err => {},
            complete: () => {}
        })
    }
}

function setCurrentProject(project) {
    currentProject = project;
}

function setRoutineList(_routineList) {
    routineList = _routineList;
}

function setPoseList(_poseList) {
    poseList = _poseList;
}

function setToolList(_toolList) {
    toolList = _toolList;
}

function getWorkspaceCode() {
    return workspaceCode;
}

function getWorkspaceXmlText() {
    return workspaceXMLText;
}

function undoWorkspaceAction() {
    blocklyWorkspace.undo(false);
}

function redoWorkspaceAction() {
    blocklyWorkspace.undo(true);
}

function convertBlockToCode(xmlText) {
    hBlocklyWorkspace = blocklyInstance.inject('helperBlocklyDiv', {});
    hBlocklyWorkspace.clear();
    let xml = blocklyInstance.Xml.textToDom(xmlText).firstChild;
    let code = '';
    if (xml !== null) {
      let block = blocklyInstance.Xml.domToBlock(xml, hBlocklyWorkspace);
      code = blocklyInstance.UR_script.blockToCode(block);
    }
    hBlocklyWorkspace.dispose();

    return code;
}

function clearWorkspace() {
    blocklyWorkspace.clear();
    let block = blocklyWorkspace.newBlock('main_block');
    block.initSvg();
    block.render();
    block.moveBy(230, 230);
}

function loadWorkspace(XMLText) {
    blocklyWorkspace.clear();
    blocklyInstance.Xml.domToWorkspace(
        blocklyInstance.Xml.textToDom(XMLText),
        blocklyWorkspace);
}

function createNewRoutineBlock(blockName, generatedCode) {
    blocklyInstance.Blocks[blockName] = {
        init: function () {
            this.appendDummyInput()
                .appendField(blockName);
            this.setPreviousStatement(true, null);
            this.setNextStatement(true, null);
            this.setColour(345);
            this.setTooltip('');
            this.setHelpUrl('');
        }
    }

    blocklyInstance.UR_script[blockName] = function(block) {
        return generatedCode;
    }

    if (window.Blockly.Blocks[blockName]) {
        let blockText = '<xml><block type="' + blockName + '"></block></xml>';
        let block = window.Blockly.Xml.textToDom(blockText).firstChild;
        return block;
    } else {
        console.log(blockName + ' does not exist');
        return null;
    }
}

function createNewPoseBlock(blockName, generatedCode, poseType) {
    blocklyInstance.Blocks[blockName] = {
        init: function () {
            this.appendDummyInput()
                .appendField(blockName);
            this.setOutput(true, poseType);
            this.setColour(230);
            this.setTooltip('');
            this.setHelpUrl('');
        }
    }

    blocklyInstance.UR_script[blockName] = function(block) {
        return generatedCode;
    }

    if (window.Blockly.Blocks[blockName]) {
        let blockText = '<xml><block type="' + blockName + '"></block></xml>';
        let block = blocklyInstance.Xml.textToDom(blockText).firstChild;
        return block;
    } else {
        console.log(blockName + ' does not exist');
        return null;
    }
}

function createNewToolBlock(blockName, tcpPose, payloadMass, payloadCog) {
    blocklyInstance.Blocks[blockName] = {
        init: function () {
            this.appendDummyInput()
                .appendField(blockName);
            this.setOutput(true, 'tool');
            this.setColour(40);
            this.setTooltip('');
            this.setHelpUrl('');
        }
    }

    blocklyInstance.UR_script[blockName] = function(block) {
        const setTcp = tcpPose !== null ? `set_tcp(p${tcpPose})\n` : ''
        const setPayload = payloadMass !== null && payloadCog !== null ? `set_payload(${payloadMass}, ${payloadCog})\n` : ''
        return setTcp + setPayload;
    }

    if (window.Blockly.Blocks[blockName]) {
        let blockText = '<xml><block type="' + blockName + '"></block></xml>';
        let block = blocklyInstance.Xml.textToDom(blockText).firstChild;
        return block;
    } else {
        console.log(blockName + ' does not exist');
        return null;
    }
}

function refreshToolboxSelection() {
    blocklyWorkspace.refreshToolboxSelection();
}
