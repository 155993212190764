import { routineConstants } from '../constants';

export function routine(state = {}, action) {
    switch (action.type) {
        case routineConstants.ROUTINE_ADDNEW_REQUEST:
            return {
                loading: true
            };
        case routineConstants.ROUTINE_ADDNEW_SUCCESS:
            return {
                routine: action.routine
            };
        case routineConstants.ROUTINE_ADDNEW_FAILURE:
            return {
                error: action.error
            };

        case routineConstants.ROUTINE_UPDATE_REQUEST:
            return {
                loading: true,
                routine: action.routine
            };
        case routineConstants.ROUTINE_UPDATE_SUCCESS:
            return {
                routine: action.routine
            };
        case routineConstants.ROUTINE_UPDATE_FAILURE:
            return {
                error: action.error
            };

        case routineConstants.ROUTINE_DELETE_REQUEST:
            return {
                loading: true
            };
        case routineConstants.ROUTINE_DELETE_SUCCESS:
            return {
                routineId: action
            };
        case routineConstants.ROUTINE_DELETE_FAILURE:
            return {
                error: action.error
            };

        case routineConstants.ROUTINE_SELECT:
            return {
                selectedRoutine: action.routine
            };

        case routineConstants.ROUTINE_GET_REQUEST:
            return {
                loading: true
            };
        case routineConstants.ROUTINE_GET_SUCCESS:
            return {
                routine: action.routine
            };
        case routineConstants.ROUTINE_GET_FAILURE:
            return {
                error: action.error
            };
        default:
            return state
    }
}