import React, { Component } from 'react';

class Timer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      hours: '',
      mins: '',
      secs: ''
    };

    this.hours = null;
    this.mins = null;
    this.secs = null;
  }

  componentDidMount() {
    const user = JSON.parse(localStorage.getItem('user'));
    this.countDownDate = new Date(0).setUTCSeconds(user.exp);

    this.interval = setInterval(() => {
      var now = new Date().getTime();
      var t = this.countDownDate - now;
      if (t >= 0) {
        this.hours = Math.floor((t % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        this.mins = Math.floor((t % (1000 * 60 * 60)) / (1000 * 60));
        this.secs = Math.floor((t % (1000 * 60)) / 1000);

        this.hours = this.hours < 10 ? '0' + this.hours : this.hours
        this.mins = this.mins < 10 ? '0' + this.mins : this.mins
        this.secs = this.secs < 10 ? '0' + this.secs : this.secs
      } else {
        // logout
        this.props.onLogout();
      }
      this.setState({
          hours: this.hours,
          mins: this.mins,
          secs: this.secs
        })
      }, 1000);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  render() {
    return (
      <React.Fragment>
        <span>{this.state.hours}:{this.state.mins}:{this.state.secs}</span>
      </React.Fragment>
    );
  }
}

export { Timer };
