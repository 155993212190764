import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Button } from 'primereact/button';
import { Growl } from 'primereact/growl';

import { Modal, ModalBody, ModalHeader, ModalFooter } from 'reactstrap';

import { toolActions } from '../../../actions';

class ToolDeleteDialog extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isDialogVisible: false,
            tool: {},
        };

        this.user = JSON.parse(localStorage.getItem('user'));
    }

    onClickDelete() {
        const { dispatch } = this.props;
        const { toolId } = this.state.tool;

        dispatch(toolActions.deleteTool(toolId))
        .subscribe(response => this.growl.show({ severity: 'success', summary: 'Delete tool', detail: response.message }));

        if(this.props.updateTable) {
            this.props.updateTable();
        }

        this.setState({
            tool: {},
            isDialogVisible: false
        });
    }

    render() {
        return (
            <React.Fragment>
                <Growl ref={(el) => this.growl = el} style={{marginTop: '50px'}} life={3000} />
                <Modal isOpen={this.state.isDialogVisible}>
                    <ModalHeader>
                        <span>Delete tool</span>
                    </ModalHeader>
                    <ModalBody>
                        <div style={{ paddingTop: '3em', paddingBottom: '3em', paddingLeft: '0.5em' }}>
                            {this.state.tool.toolName}<span> will be deleted. This action cannot be undone!</span>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <Button className="common-button" label="Confirm" icon="pi pi-check" onClick={() => this.onClickDelete()} />
                        <Button className="common-button" label="Cancel" icon="pi pi-times" onClick={() => this.setState({ isDialogVisible: false})} />
                    </ModalFooter>

                </Modal>
            </React.Fragment>
        );
    }
}

function mapStateToProps(state) {
    const { alert, authentication } = state;
    const { user } = authentication;
    return {
        alert,
        authentication,
        user
    };
}

const ToolDeleteDialogComp = connect(mapStateToProps, null, null, { forwardRef: true })(ToolDeleteDialog);
export { ToolDeleteDialogComp as ToolDeleteDialog };
