import { Observable } from 'rxjs';
import { routineConstants } from '../constants';
import { routineService } from '../services';
import { alertActions } from '.';

export const routineActions = {
    addNew,
    update,
    deleteRoutine,
    selectRoutine,
    getRoutine
};

function addNew(userId, routineName, description) {
    return dispatch => {

        return Observable.create((observer) => {

            dispatch(request({ routineName }));

            routineService.addNew(userId, routineName, description)
            .subscribe({
                next: response => {
                    dispatch(success(routineName));
                    dispatch(alertActions.success(response.message));
                    observer.next(response);
                    observer.complete();
                },
                error: err => {
                    console.log(err);
                    dispatch(failure(err.message));
                    dispatch(alertActions.error(err.message));
                    observer.error(err);
                }
            })
        })
    };

    function request(routine) { return { type: routineConstants.ROUTINE_ADDNEW_REQUEST, routine } }
    function success(routine) { return { type: routineConstants.ROUTINE_ADDNEW_SUCCESS, routine } }
    function failure(error) { return { type: routineConstants.ROUTINE_ADDNEW_FAILURE, error } }
}

function update(routine) {
    return dispatch => {

        return Observable.create((observer) => {

            dispatch(request({ routine }));

            routineService.updateRoutine(routine)
            .subscribe({
                next: response => {
                    dispatch(success(routine));
                    dispatch(alertActions.success(response.message));
                    observer.next(response);
                    observer.complete();
                },
                error: err => {
                    console.log(err);
                    dispatch(failure(err.message));
                    dispatch(alertActions.error(err.message));
                    observer.error(err);
                }
            })
        })
    };

    function request(routine) { return { type: routineConstants.ROUTINE_UPDATE_REQUEST, routine } }
    function success(routine) { return { type: routineConstants.ROUTINE_UPDATE_SUCCESS, routine } }
    function failure(error) { return { type: routineConstants.ROUTINE_UPDATE_FAILURE, error } }
}

function deleteRoutine(routineId) {
    return dispatch => {

        return Observable.create((observer) => {

            dispatch(request(routineId));

            routineService.deleteRoutine(routineId)
            .subscribe({
                next: response => {
                    dispatch(success(routineId));
                    dispatch(alertActions.success(response.message));
                    observer.next(response)
                    observer.complete()
                },
                error: err => {
                    console.log(err);
                    dispatch(failure(err.message));
                    dispatch(alertActions.error(err.message));
                    observer.error(err)
                }
            })
        })
    };

    function request(routineId) { return { type: routineConstants.ROUTINE_DELETE_REQUEST, routineId } }
    function success(routineId) { return { type: routineConstants.ROUTINE_DELETE_SUCCESS, routineId } }
    function failure(error) { return { type: routineConstants.ROUTINE_DELETE_FAILURE, error } }
}

function selectRoutine(routine) {
    return dispatch => {
        dispatch(command(routine));
    };

    function command(routine) { return { type: routineConstants.ROUTINE_SELECT , routine} }
}

function getRoutine(routine) {
    return dispatch => {

        return Observable.create((observer) => {

            dispatch(request(routine.routineId));

            routineService.getRoutine(routine.routineId)
            .subscribe({
                next: response => {
                    dispatch(success(routine));
                    dispatch(alertActions.success(response.message));
                    observer.next(response)
                    observer.complete()
                },
                error: err => {
                    console.log(err);
                    dispatch(failure(err.message));
                    dispatch(alertActions.error(err.message));
                    observer.error(err)
                }
            })
        })
    };

    function request(routine) { return { type: routineConstants.ROUTINE_GET_REQUEST, routine } }
    function success(routine) { return { type: routineConstants.ROUTINE_GET_SUCCESS, routine } }
    function failure(error) { return { type: routineConstants.ROUTINE_GET_FAILURE, error } }
}
