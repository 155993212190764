import axios from 'axios';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

export const projectService = {
    addNew,
    updateProject,
    deleteProject,
    getProject
}

function addNew(userId, projectName, description) {
    return Observable.create((observer) => {
        axios.post('/api/project', {
            userId: userId,
            projectName: projectName,
            description: description
        })
        .then(response => {
            observer.next(response.data)
            observer.complete()
        })
        .catch(err => {
            observer.error(err)
        })
    })
    .pipe(
        tap(response => {
            if (response.success !== true) {
                throw response.message;
            }
        })
    )
}

/**
 * @param {JSON object} project - properties: projectId, projectName, projectDefinition, projectDescription
 */
function updateProject(project) {
    return Observable.create((observer) => {
        axios.put('/api/project', project)
        .then(response => {
            observer.next(response.data)
            observer.complete()
        })
        .catch(err => {
            observer.error(err)
        })
    })
    .pipe(
        tap(response => {
            if (response.success !== true) {
                throw response.message;
            }
        })
    )
}

function deleteProject(projectId) {
    // var params = new URLSearchParams();
    // params.append('id', projectId);

    return Observable.create((observer) => {
        axios.delete('/api/projects/'+projectId)
        .then(response => {
            observer.next(response.data)
            observer.complete()
        })
        .catch(err => {
            observer.error(err)
        })
    })
    .pipe(
        tap(response => {
            if (response.success !== true) {
                throw response.message;
            }
        })
    )
}

function getProject(projectId) {
    return Observable.create((observer) => {
        axios.get('/api/projects/'+projectId)
        .then(response => {
            observer.next(response.data)
            observer.complete()
        })
        .catch(err => {
            observer.error(err)
        })
    })
    .pipe(
        tap(response => {
            if (response.success !== true) {
                throw response.message;
            }
        })
    )
}