import 'react-app-polyfill/ie9'; // For IE 9-11 support
import 'react-app-polyfill/ie11'; // For IE 11 support
import './polyfill'
import React from 'react';
import ReactDOM from 'react-dom';
import axios from 'axios';
import './index.css';
import { App } from './App';
import * as serviceWorker from './serviceWorker';

import { Provider } from 'react-redux';
import { store } from './helpers';

axios.interceptors.request.use(
    request => {
        console.log('Backend urL: \n')
        console.log(process.env.BACKENDURL)
        request.baseURL = process.env.BACKENDURL

        if (localStorage.getItem('user') && JSON.parse(localStorage.getItem('user')).token) {
            let token = JSON.parse(localStorage.getItem('user')).token;
            request.headers['authorization'] = `Bearer ${token}`

        }
        return request;
    },
    error => Promise.reject(error)
)

axios.interceptors.response.use(
  response => response,
  error => {
      console.log(error.response);
      return error.response;
  }
)

ReactDOM.render(
    <Provider store={store} >
        <App />
    </Provider>,
    document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
