import { Observable } from 'rxjs';
import { poseConstants } from '../constants';
import { poseService } from '../services';
import { alertActions } from './';

export const poseActions = {
    addNew,
    deletePose,
    getPose,
    update
};

function addNew(userId, poseName, code, poseType, description) {
    return dispatch => {

        return Observable.create((observer) => {

            dispatch(request({ poseName }));

                poseService.addNew(userId, poseName, code, poseType, description)
                .subscribe({
                    next: response => {
                        dispatch(success(poseName));
                        dispatch(alertActions.success(response.message));
                        observer.next(response);
                        observer.complete();
                    },
                    error: err => {
                        console.log(err);
                        dispatch(failure(err.message));
                        dispatch(alertActions.error(err.message));
                        observer.error(err);
                    }
                })
            })
    };

    function request(pose) { return { type: poseConstants.POSE_ADDNEW_REQUEST, pose } }
    function success(pose) { return { type: poseConstants.POSE_ADDNEW_SUCCESS, pose } }
    function failure(error) { return { type: poseConstants.POSE_ADDNEW_FAILURE, error } }
}

function deletePose(poseId) {
    return dispatch => {

        return Observable.create((observer) => {

            dispatch(request(poseId));

            poseService.deletePose(poseId)
            .subscribe({
                next: response => {
                    dispatch(success(poseId));
                    dispatch(alertActions.success(response.message));
                    observer.next(response);
                    observer.complete();
                },
                error: err => {
                    console.log(err);
                    dispatch(failure(err.message));
                    dispatch(alertActions.error(err.message));
                    observer.error(err);
                }
            })
        })
    };

    function request(poseId) { return { type: poseConstants.POSE_DELETE_REQUEST, poseId } }
    function success(poseId) { return { type: poseConstants.POSE_DELETE_SUCCESS, poseId } }
    function failure(error) { return { type: poseConstants.POSE_DELETE_FAILURE, error } }

}

function getPose(poseId) {
    return dispatch => {

        return Observable.create((observer) => {

            dispatch(request(poseId));

            poseService.getPose(poseId)
            .subscribe({
                next: response => {
                    dispatch(success(response.data));
                    dispatch(alertActions.success(response.message));
                    observer.next(response);
                    observer.complete();
                },
                error: err => {
                    console.log(err);
                    dispatch(failure(err.message));
                    dispatch(alertActions.error(err.message));
                    observer.error(err);
                }
            })
        })
    };

    function request(pose) { return { type: poseConstants.POSE_GETPOSE_REQUEST, pose } }
    function success(pose) { return { type: poseConstants.POSE_GETPOSE_SUCCESS, pose } }
    function failure(error) { return { type: poseConstants.POSE_GPOSEON_FAILURE, error } }
}

function update(pose) {
    return dispatch => {

        return Observable.create((observer) => {

            dispatch(request({ pose }));

            poseService.updatePose(pose)
            .subscribe({
                next: response => {
                    dispatch(success(pose));
                    dispatch(alertActions.success(response.message));
                    observer.next(response);
                    observer.complete();
                },
                error: err => {
                    console.log(err);
                    dispatch(failure(err.message));
                    dispatch(alertActions.error(err.message));
                    observer.error(err);
                }
            })
        })
    };

    function request(pose) { return { type: poseConstants.POSE_UPDATE_REQUEST, pose } }
    function success(pose) { return { type: poseConstants.POSE_UPDATE_SUCCESS, pose } }
    function failure(error) { return { type: poseConstants.POSE_UPDATE_FAILURE, error } }
}
