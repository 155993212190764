import { projectConstants } from '../constants';

export function project(state = {}, action) {
    switch (action.type) {
        case projectConstants.PROJECT_ADDNEW_REQUEST:
            return {
                loading: true
            };
        case projectConstants.PROJECT_ADDNEW_SUCCESS:
            return {
                project: action.project
            };
        case projectConstants.PROJECT_ADDNEW_FAILURE:
            return {
                error: action.error
            };

        case projectConstants.PROJECT_UPDATE_REQUEST:
            return {
                loading: true,
                project: action.project
            };
        case projectConstants.PROJECT_UPDATE_SUCCESS:
            return {
                project: action.project
            };
        case projectConstants.PROJECT_UPDATE_FAILURE:
            return {
                error: action.error
            };

        case projectConstants.PROJECT_DELETE_REQUEST:
            return {
                loading: true
            };
        case projectConstants.PROJECT_DELETE_SUCCESS:
            return {
                projectId: action
            };
        case projectConstants.PROJECT_DELETE_FAILURE:
            return {
                error: action.error
            };

        case projectConstants.PROJECT_SELECT:
            return {
                selectedProject: action.project
            };

        case projectConstants.PROJECT_GET_REQUEST:
            return {
                loading: true
            };
        case projectConstants.PROJECT_GET_SUCCESS:
            return {
                project: action.project
            };
        case projectConstants.PROJECT_GET_FAILURE:
            return {
                error: action.error
            };
        default:
            return state
    }
}