import { Observable } from 'rxjs';
import { projectConstants } from '../constants';
import { projectService } from '../services';
import { alertActions } from './';

export const projectActions = {
    addNew,
    update,
    deleteProject,
    selectProject,
    getProject
};

function addNew(userId, projectName, description) {
    return dispatch => {

        return Observable.create((observer) => {

            dispatch(request({ projectName }));

            projectService.addNew(userId, projectName, description)
            .subscribe({
                next: response => {
                    dispatch(success(projectName));
                    dispatch(alertActions.success(response.message));
                    observer.next(response);
                    observer.complete();
                },
                error: err => {
                    console.log(err);
                    dispatch(failure(err.message));
                    dispatch(alertActions.error(err.message));
                    observer.error(err);
                }
            })
        })
    };

    function request(project) { return { type: projectConstants.PROJECT_ADDNEW_REQUEST, project } }
    function success(project) { return { type: projectConstants.PROJECT_ADDNEW_SUCCESS, project } }
    function failure(error) { return { type: projectConstants.PROJECT_ADDNEW_FAILURE, error } }
}

function update(project) {
    return dispatch => {

        return Observable.create((observer) => {

            dispatch(request({ project }));

            projectService.updateProject(project)
            .subscribe({
                next: response => {
                    dispatch(success(project));
                    dispatch(alertActions.success(response.message));
                    observer.next(response);
                    observer.complete();
                },
                error: err => {
                    console.log(err);
                    dispatch(failure(err.message));
                    dispatch(alertActions.error(err.message));
                    observer.error(err);
                }
            })
        })
    };

    function request(project) { return { type: projectConstants.PROJECT_UPDATE_REQUEST, project } }
    function success(project) { return { type: projectConstants.PROJECT_UPDATE_SUCCESS, project } }
    function failure(error) { return { type: projectConstants.PROJECT_UPDATE_FAILURE, error } }
}

function deleteProject(projectId) {
    return dispatch => {

        return Observable.create((observer) => {

            dispatch(request(projectId));

            projectService.deleteProject(projectId)
            .subscribe({
                next: response => {
                    dispatch(success(projectId));
                    dispatch(alertActions.success(response.message));
                    observer.next(response);
                    observer.complete();
                },
                error: err => {
                    console.log(err);
                    dispatch(failure(err.message));
                    dispatch(alertActions.error(err.message));
                    observer.error(err);
                }
            })
        })
    };

    function request(projectId) { return { type: projectConstants.PROJECT_DELETE_REQUEST, projectId } }
    function success(projectId) { return { type: projectConstants.PROJECT_DELETE_SUCCESS, projectId } }
    function failure(error) { return { type: projectConstants.PROJECT_DELETE_FAILURE, error } }
}

function selectProject(project) {
    return dispatch => {
        dispatch(command(project));
    };

    function command(project) { return { type: projectConstants.PROJECT_SELECT , project} }
}

function getProject(project) {
    return dispatch => {

        return Observable.create((observer) => {

            dispatch(request(project.projectId));

            projectService.getProject(project.projectId)
            .subscribe({
                next: response => {
                    dispatch(success(project));
                    dispatch(alertActions.success(response.message));
                    observer.next(response);
                    observer.complete();
                },
                error: err => {
                    console.log(err);
                    dispatch(failure(err.message));
                    dispatch(alertActions.error(err.message));
                    observer.error(err);
                }
            })
        })
    };

    function request(project) { return { type: projectConstants.PROJECT_GET_REQUEST, project } }
    function success(project) { return { type: projectConstants.PROJECT_GET_SUCCESS, project } }
    function failure(error) { return { type: projectConstants.PROJECT_GET_FAILURE, error } }
}
