import React, { Component } from 'react';
import { connect } from 'react-redux';
import { flatMap } from 'rxjs/operators';

import { Modal, ModalBody, ModalHeader } from 'reactstrap';

import { blocklyService } from '../../services';
import { poseActions, capsuleActions, userActions } from '../../actions';

import negXX from '../../assets/img/jogging-arrow/SVG/-x.svg';
import posXX from '../../assets/img/jogging-arrow/SVG/+x.svg';
import negYY from '../../assets/img/jogging-arrow/SVG/-y.svg';
import posYY from '../../assets/img/jogging-arrow/SVG/+y.svg';
import negZZ from '../../assets/img/jogging-arrow/SVG/-z.svg';
import posZZ from '../../assets/img/jogging-arrow/SVG/+z.svg';
import negRX from '../../assets/img/jogging-arrow/SVG/-rx.svg';
import posRX from '../../assets/img/jogging-arrow/SVG/+rx.svg';
import negRY from '../../assets/img/jogging-arrow/SVG/-ry.svg';
import posRY from '../../assets/img/jogging-arrow/SVG/+ry.svg';
import negRZ from '../../assets/img/jogging-arrow/SVG/-rz.svg';
import posRZ from '../../assets/img/jogging-arrow/SVG/+rz.svg';
import negJ from '../../assets/img/jogging-arrow/SVG/-j.svg';
import posJ from '../../assets/img/jogging-arrow/SVG/+j.svg';
import { Button } from 'primereact/button';

class JoggingOverlay extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isDialogVisible: false
        };

        //cartesian
        this.codeNegX = '  servoj(get_inverse_kin(pose_add(get_actual_tcp_pose(), p[-0.04, 0, 0, 0, 0, 0])), t=0.9, lookahead_time=0.1, gain=300)\n';
        this.codePosX = '  servoj(get_inverse_kin(pose_add(get_actual_tcp_pose(), p[0.04, 0, 0, 0, 0, 0])), t=0.9, lookahead_time=0.1, gain=300)\n';
        this.codeNegY = '  servoj(get_inverse_kin(pose_add(get_actual_tcp_pose(), p[0, -0.04, 0, 0, 0, 0])), t=0.9, lookahead_time=0.1, gain=300)\n';
        this.codePosY = '  servoj(get_inverse_kin(pose_add(get_actual_tcp_pose(), p[0, 0.04, 0, 0, 0, 0])), t=0.9, lookahead_time=0.1, gain=300)\n';
        this.codeNegZ = '  servoj(get_inverse_kin(pose_add(get_actual_tcp_pose(), p[0, 0, -0.04, 0, 0, 0])), t=0.9, lookahead_time=0.1, gain=300)\n';
        this.codePosZ = '  servoj(get_inverse_kin(pose_add(get_actual_tcp_pose(), p[0, 0, 0.04, 0, 0, 0])), t=0.9, lookahead_time=0.1, gain=300)\n';
        this.codeNegRX = '  servoj(get_inverse_kin(pose_add(get_actual_tcp_pose(), p[0, 0, 0, -0.04, 0, 0])), t=0.9, lookahead_time=0.1, gain=300)\n';
        this.codePosRX = '  servoj(get_inverse_kin(pose_add(get_actual_tcp_pose(), p[0, 0, 0, 0.04, 0, 0])), t=0.9, lookahead_time=0.1, gain=300)\n';
        this.codeNegRY = '  servoj(get_inverse_kin(pose_add(get_actual_tcp_pose(), p[0, 0, 0, 0, -0.04, 0])), t=0.9, lookahead_time=0.1, gain=300)\n';
        this.codePosRY = '  servoj(get_inverse_kin(pose_add(get_actual_tcp_pose(), p[0, 0, 0, 0, 0.04, 0])), t=0.9, lookahead_time=0.1, gain=300)\n';
        this.codeNegRZ = '  servoj(get_inverse_kin(pose_add(get_actual_tcp_pose(), p[0, 0, 0, 0, 0, -0.04])), t=0.9, lookahead_time=0.1, gain=300)\n';
        this.codePosRZ = '  servoj(get_inverse_kin(pose_add(get_actual_tcp_pose(), p[0, 0, 0, 0, 0, 0.04])), t=0.9, lookahead_time=0.1, gain=300)\n';
        //joint
        this.codeNegJ1 = this.jointTemplate(-0.15, 0, 0, 0, 0, 0);
        this.codePosJ1 = this.jointTemplate(0.15, 0, 0, 0, 0, 0);
        this.codeNegJ2 = this.jointTemplate(0, -0.15, 0, 0, 0, 0);
        this.codePosJ2 = this.jointTemplate(0, 0.15, 0, 0, 0, 0);
        this.codeNegJ3 = this.jointTemplate(0, 0, -0.15, 0, 0, 0);
        this.codePosJ3 = this.jointTemplate(0, 0, 0.15, 0, 0, 0);
        this.codeNegJ4 = this.jointTemplate(0, 0, 0, -0.15, 0, 0);
        this.codePosJ4 = this.jointTemplate(0, 0, 0, 0.15, 0, 0);
        this.codeNegJ5 = this.jointTemplate(0, 0, 0, 0, -0.15, 0);
        this.codePosJ5 = this.jointTemplate(0, 0, 0, 0, 0.15, 0);
        this.codeNegJ6 = this.jointTemplate(0, 0, 0, 0, 0, -0.15);
        this.codePosJ6 = this.jointTemplate(0, 0, 0, 0, 0, 0.15);

        this.user = JSON.parse(localStorage.getItem('user'));
        this.sid = localStorage.getItem('rrsmSid') ? JSON.parse(localStorage.getItem('rrsmSid')).sid : undefined;
        this.interval = null;
        this.code = null;
    }

    componentDidMount() {
        capsuleActions.connectWebSocket(this.sid);
    }

    componentWillUnmount() {
        capsuleActions.disconnectWebSocket();
    }

    jointTemplate(j1=0, j2=0, j3=0, j4=0, j5=0, j6=0) {
        let code =
            `arr = get_actual_joint_positions()
            servoj([arr[0] + ${j1}, arr[1] + ${j2}, arr[2] + ${j3}, arr[3] + ${j4}, arr[4] + ${j5}, arr[5] + ${j6}], t=0.9, lookahead_time=0.1, gain=300)`.replace(/^[ ]*/gm, '  ');
        return code;
    }

    functionTemplate(code) {
        const functionStart = 'def MyFunction():\n';
        const functionEnd = '\nend\n';
        return functionStart + code + functionEnd;
    }

    onClickJogging(code) {
        capsuleActions.sendJoggingProgramOnWebSocket(this.functionTemplate(code));
        this.interval = setInterval(() => {
            capsuleActions.sendJoggingProgramOnWebSocket(this.functionTemplate(code));
        }, 600);
    }

    stopMotion() {
        clearInterval(this.interval);
        const haltCommand = 'stopj(5)\n';
        capsuleActions.sendJoggingProgramOnWebSocket(haltCommand);
    }

    onClickMoveToHomePose() {
        const { dispatch } = this.props;
        this.code = this.functionTemplate('movej([-0.389, -1.727, -2.202, -0.807, 1.595, -0.030], a=1, v=1)\n');
        dispatch(capsuleActions.sendProgram(this.sid, this.code))
        .subscribe({
            next: response => console.log(response),
            error: err => console.log(err)
        });
    }

    onClickCreateJointPose() {
        const { dispatch } = this.props;
        const result = window.prompt('New joint-pose name:');
        dispatch(capsuleActions.getCurrentState(this.sid))
        .pipe(
            flatMap(response => {
                const code = '[' + response.data.actJ1pos + ', ' + response.data.actJ2pos + ', ' + response.data.actJ3pos + ', ' + response.data.actJ4pos + ', ' + response.data.actJ5pos + ', ' + response.data.actJ6pos + ']';
                return dispatch(poseActions.addNew(this.user.id, result, code, 'joint-type'))
            }),
            flatMap(() => dispatch(userActions.getPoses(this.user.id)))
        )
        .subscribe({
            next: response => {
                let poseList = [];
                response.data.forEach(pose => {
                    let block = blocklyService.createNewPoseBlock(pose.poseName, pose.code, pose.poseType);
                    poseList.push(block);
                });
                blocklyService.setPoseList(poseList);
                blocklyService.refreshToolboxSelection();
            },
            error: err => console.log(err)
        })
    }

    onClickCreateCartPose() {
        const { dispatch } = this.props;
        const result = window.prompt('New cart-pose name:');
        dispatch(capsuleActions.getCurrentState(this.sid))
        .pipe(
            flatMap(response => {
                const code = 'p[' + response.data.actXXpos + ', ' + response.data.actYYpos + ', ' + response.data.actZZpos + ', ' + response.data.actRXpos + ', ' + response.data.actRYpos + ', ' + response.data.actRZpos + ']';
                return dispatch(poseActions.addNew(this.user.id, result, code, 'cart-type'))
            }),
            flatMap(() => dispatch(userActions.getPoses(this.user.id)))
        )
        .subscribe({
            next: response => {
                let poseList = [];
                response.data.forEach(pose => {
                    let block = blocklyService.createNewPoseBlock(pose.poseName, pose.code, pose.poseType);
                    poseList.push(block);
                });
                blocklyService.setPoseList(poseList);
                blocklyService.refreshToolboxSelection();
            },
            error: err => console.log(err)
        })
    }

    toggle() {
        this.setState({ isDialogVisible: !this.state.isDialogVisible });
    }

    render() {
        const { capsuleState } = this.props;
        let actXXpos, actYYpos, actZZpos, actRXpos, actRYpos, actRZpos, actJ1pos, actJ2pos, actJ3pos, actJ4pos, actJ5pos, actJ6pos = null;
        if (capsuleState && capsuleState.state) {
            actXXpos = capsuleState.state.actXXpos;
            actYYpos = capsuleState.state.actYYpos;
            actZZpos = capsuleState.state.actZZpos;
            actRXpos = capsuleState.state.actRXpos;
            actRYpos = capsuleState.state.actRYpos;
            actRZpos = capsuleState.state.actRZpos;
            actJ1pos = capsuleState.state.actJ1pos;
            actJ2pos = capsuleState.state.actJ2pos;
            actJ3pos = capsuleState.state.actJ3pos;
            actJ4pos = capsuleState.state.actJ4pos;
            actJ5pos = capsuleState.state.actJ5pos;
            actJ6pos = capsuleState.state.actJ6pos;
        }
        return (
            <React.Fragment>
                <Modal isOpen={this.state.isDialogVisible} toggle={() => this.toggle()} size={'lg'} scrollable={true} style={{ width: '800px', height: '575px' }}>
                    <ModalHeader toggle={() => this.toggle()} charCode="x">
                        <span>Jogging</span>
                    </ModalHeader>
                    <ModalBody>
                        <div className="row justify-content-center h-100">
                            <div className="col-6">
                                <div className="row justify-content-center">
                                    <img src={negZZ} alt="-Z" style={{ cursor: 'pointer', height: "25%", width: "25%", marginRight: '10px' }} onMouseDown={() => this.onClickJogging(this.codeNegZ)} onMouseUp={() => this.stopMotion()} />
                                    <img src={posZZ} alt="+Z" style={{ cursor: 'pointer', height: "25%", width: "25%", marginLeft: '10px' }} onMouseDown={() => this.onClickJogging(this.codePosZ)} onMouseUp={() => this.stopMotion()} />
                                </div>
                                <div className="row justify-content-center">
                                    <img src={negXX} alt="-X" style={{ cursor: 'pointer', height: "25%", width: "25%", marginBottom: '-10px' }} onMouseDown={() => this.onClickJogging(this.codeNegX)} onMouseUp={() => this.stopMotion()} />
                                </div>
                                <div className="row justify-content-center">
                                    <img src={negYY} alt="-Y" style={{ cursor: 'pointer', height: "30%", width: "30%", paddingRight: '28px' }} onMouseDown={() => this.onClickJogging(this.codeNegY)} onMouseUp={() => this.stopMotion()} />
                                    <img src={posYY} alt="+Y" style={{ cursor: 'pointer', height: "30%", width: "30%", paddingLeft: '28px' }} onMouseDown={() => this.onClickJogging(this.codePosY)} onMouseUp={() => this.stopMotion()} />
                                </div>
                                <div className="row justify-content-center mb-3">
                                    <img src={posXX} alt="+X" style={{ cursor: 'pointer', height: "30%", width: "30%", marginTop: '-15px' }} onMouseDown={() => this.onClickJogging(this.codePosX)} onMouseUp={() => this.stopMotion()} />
                                </div>

                                <div className="row justify-content-center">
                                    <img src={posRX} alt="+RX" style={{ cursor: 'pointer', height: "25%", width: "25%" }} onMouseDown={() => this.onClickJogging(this.codePosRX)} onMouseUp={() => this.stopMotion()} />
                                    <img src={negRX} alt="-RX" style={{ cursor: 'pointer', height: "25%", width: "25%" }} onMouseDown={() => this.onClickJogging(this.codeNegRX)} onMouseUp={() => this.stopMotion()} />
                                </div>

                                <div className="row justify-content-center">
                                    <img src={posRY} alt="+RY" style={{ cursor: 'pointer', height: "18%", width: "18%", marginBottom: '-17px' }} onMouseDown={() => this.onClickJogging(this.codePosRY)} onMouseUp={() => this.stopMotion()} />
                                </div>
                                <div className="row justify-content-center">
                                    <img src={negRZ} alt="-RZ" style={{ cursor: 'pointer', height: "20%", width: "20%", paddingRight: '17px' }} onMouseDown={() => this.onClickJogging(this.codeNegRZ)} onMouseUp={() => this.stopMotion()} />
                                    <img src={posRZ} alt="+RZ" style={{ cursor: 'pointer', height: "20%", width: "20%", paddingLeft: '17px' }} onMouseDown={() => this.onClickJogging(this.codePosRZ)} onMouseUp={() => this.stopMotion()} />
                                </div>
                                <div className="row justify-content-center">
                                    <img src={negRY} alt="-RY" style={{ cursor: 'pointer', height: "18%", width: "18%", marginTop: '-17px' }} onMouseDown={() => this.onClickJogging(this.codeNegRY)} onMouseUp={() => this.stopMotion()} />
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="container h-100">
                                    <div className="row justify-content-center" style={{ height: '5%' }}>
                                        <Button label="Home" className="p-button-rounded common-button" onClick={() => this.onClickMoveToHomePose()} />
                                        <Button label="Create joint pose" className="p-button-rounded common-button" onClick={() => this.onClickCreateJointPose()} />
                                        <Button label="Create cart pose" className="p-button-rounded common-button" onClick={() => this.onClickCreateCartPose()} />
                                    </div>
                                    <div className="row justify-content-around" style={{ height: '40%' }}>
                                        <div className="col-4 align-self-center">
                                            <div className="row justify-content-center">
                                                <h4>TCP</h4>
                                            </div>
                                            <div className="row justify-content-between">
                                                <b><span>X</span></b>
                                                <span>{actXXpos ? (actXXpos * 100).toFixed(2) + ' mm' : 'Loading...'}</span>
                                            </div>
                                            <div className="row justify-content-between">
                                                <b><span>Y</span></b>
                                                <span>{actYYpos ? (actYYpos * 100).toFixed(2) + ' mm' : 'Loading...'}</span>
                                            </div>
                                            <div className="row justify-content-between">
                                                <b><span>Z</span></b>
                                                <span>{actZZpos ? (actZZpos * 100).toFixed(2) + ' mm' : 'Loading...'}</span>
                                            </div>
                                            <div className="row justify-content-between">
                                                <b><span>RX</span></b>
                                                <span>{actRXpos ? (actRXpos).toFixed(4) + ' rad' : 'Loading...'}</span>
                                            </div>
                                            <div className="row justify-content-between">
                                                <b><span>RY</span></b>
                                                <span>{actRYpos ? (actRYpos).toFixed(4) + ' rad' : 'Loading...'}</span>
                                            </div>
                                            <div className="row justify-content-between">
                                                <b><span>RZ</span></b>
                                                <span>{actRZpos ? (actRZpos).toFixed(4) + ' rad' : 'Loading...'}</span>
                                            </div>
                                        </div>

                                        <div className="col-3 align-self-center">
                                            <div className="row justify-content-center">
                                                <h4>JOINTS</h4>
                                            </div>
                                            <div className="row justify-content-between">
                                                <b><span>J1</span></b>
                                                <span>{actJ1pos ? (actJ1pos * (180 / Math.PI)).toFixed(2) + String.fromCharCode(176) : 'Loading...'}</span>
                                            </div>
                                            <div className="row justify-content-between">
                                                <b><span>J2</span></b>
                                                <span>{actJ2pos ? (actJ2pos * (180 / Math.PI)).toFixed(2) + String.fromCharCode(176) : 'Loading...'}</span>
                                            </div>
                                            <div className="row justify-content-between">
                                                <b><span>J3</span></b>
                                                <span>{actJ3pos ? (actJ3pos * (180 / Math.PI)).toFixed(2) + String.fromCharCode(176) : 'Loading...'}</span>
                                            </div>
                                            <div className="row justify-content-between">
                                                <b><span>J4</span></b>
                                                <span>{actJ4pos ? (actJ4pos * (180 / Math.PI)).toFixed(2) + String.fromCharCode(176) : 'Loading...'}</span>
                                            </div>
                                            <div className="row justify-content-between">
                                                <b><span>J5</span></b>
                                                <span>{actJ5pos ? (actJ5pos * (180 / Math.PI)).toFixed(2) + String.fromCharCode(176) : 'Loading...'}</span>
                                            </div>
                                            <div className="row justify-content-between">
                                                <b><span>J6</span></b>
                                                <span>{actJ6pos ? (actJ6pos * (180 / Math.PI)).toFixed(2) + String.fromCharCode(176) : 'Loading...'}</span>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row h-50 justify-content-center">
                                        <div className="col-12 align-self-end">

                                            <div className="row justify-content-center align-items-center">
                                                <img src={negJ} alt="-J1" style={{ cursor: 'pointer', height: "10%", width: "10%" }} onMouseDown={() => this.onClickJogging(this.codeNegJ1)} onMouseUp={() => this.stopMotion()} />
                                                <div className="col-6 text-center"><span>Base</span></div>
                                                <img src={posJ} alt="+J1" style={{ cursor: 'pointer', height: "10%", width: "10%" }} onMouseDown={() => this.onClickJogging(this.codePosJ1)} onMouseUp={() => this.stopMotion()} />
                                            </div>
                                            <div className="row justify-content-center align-items-center">
                                                <img src={negJ} alt="-J2" style={{ cursor: 'pointer', height: "10%", width: "10%" }} onMouseDown={() => this.onClickJogging(this.codeNegJ2)} onMouseUp={() => this.stopMotion()} />
                                                <div className="col-6 text-center"><span>Shoulder</span></div>
                                                <img src={posJ} alt="+J2" style={{ cursor: 'pointer', height: "10%", width: "10%" }} onMouseDown={() => this.onClickJogging(this.codePosJ2)} onMouseUp={() => this.stopMotion()} />
                                            </div>
                                            <div className="row justify-content-center align-items-center">
                                                <img src={negJ} alt="-J3" style={{ cursor: 'pointer', height: "10%", width: "10%" }} onMouseDown={() => this.onClickJogging(this.codeNegJ3)} onMouseUp={() => this.stopMotion()} />
                                                <div className="col-6 text-center"><span>Elbow</span></div>
                                                <img src={posJ} alt="+J3" style={{ cursor: 'pointer', height: "10%", width: "10%" }} onMouseDown={() => this.onClickJogging(this.codePosJ3)} onMouseUp={() => this.stopMotion()} />
                                            </div>
                                            <div className="row justify-content-center align-items-center">
                                                <img src={negJ} alt="-J4" style={{ cursor: 'pointer', height: "10%", width: "10%" }} onMouseDown={() => this.onClickJogging(this.codeNegJ4)} onMouseUp={() => this.stopMotion()} />
                                                <div className="col-6 text-center"><span>Wrist 1</span></div>
                                                <img src={posJ} alt="+J4" style={{ cursor: 'pointer', height: "10%", width: "10%" }} onMouseDown={() => this.onClickJogging(this.codePosJ4)} onMouseUp={() => this.stopMotion()} />
                                            </div>
                                            <div className="row justify-content-center align-items-center">
                                                <img src={negJ} alt="-J5" style={{ cursor: 'pointer', height: "10%", width: "10%" }} onMouseDown={() => this.onClickJogging(this.codeNegJ5)} onMouseUp={() => this.stopMotion()} />
                                                <div className="col-6 text-center"><span>Wrist 2</span></div>
                                                <img src={posJ} alt="+J5" style={{ cursor: 'pointer', height: "10%", width: "10%" }} onMouseDown={() => this.onClickJogging(this.codePosJ5)} onMouseUp={() => this.stopMotion()} />
                                            </div>
                                            <div className="row justify-content-center align-items-center">
                                                <img src={negJ} alt="-J6" style={{ cursor: 'pointer', height: "10%", width: "10%" }} onMouseDown={() => this.onClickJogging(this.codeNegJ6)} onMouseUp={() => this.stopMotion()} />
                                                <div className="col-6 text-center"><span>Wrist 3</span></div>
                                                <img src={posJ} alt="+J6" style={{ cursor: 'pointer', height: "10%", width: "10%" }} onMouseDown={() => this.onClickJogging(this.codePosJ6)} onMouseUp={() => this.stopMotion()} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </ModalBody>
                </Modal>
            </React.Fragment>
        );
    }
}

function mapStateToProps(state) {
  const { alert, authentication, capsule } = state;
  const { user } = authentication;
  const { capsuleState } = capsule;
  return {
    alert,
    authentication,
    user,
    capsuleState
  };
}

const JoggingComp = connect(mapStateToProps, null, null, { forwardRef: true})(JoggingOverlay);
export { JoggingComp as JoggingOverlay };
