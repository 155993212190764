import { Observable } from 'rxjs';
import { toolConstants } from '../constants';
import { toolService } from '../services';
import { alertActions } from './';

export const toolActions = {
    addNew,
    deleteTool,
    getTool,
    update
};

function addNew(userId, toolName, description, tcpPose, payloadMass, payloadCog) {
    return dispatch => {

        return Observable.create((observer) => {

            dispatch(request({ toolName }));

                toolService.addNew(userId, toolName, description, tcpPose, payloadMass, payloadCog)
                .subscribe({
                    next: response => {
                        dispatch(success(toolName));
                        dispatch(alertActions.success(response.message));
                        observer.next(response);
                        observer.complete();
                    },
                    error: err => {
                        console.log(err);
                        dispatch(failure(err.message));
                        dispatch(alertActions.error(err.message));
                        observer.error(err);
                    }
                })
            })
    };

    function request(tool) { return { type: toolConstants.TOOL_ADDNEW_REQUEST, tool } }
    function success(tool) { return { type: toolConstants.TOOL_ADDNEW_SUCCESS, tool } }
    function failure(error) { return { type: toolConstants.TOOL_ADDNEW_FAILURE, error } }
}

function deleteTool(toolId) {
    return dispatch => {

        return Observable.create((observer) => {

            dispatch(request(toolId));

            toolService.deleteTool(toolId)
            .subscribe({
                next: response => {
                    dispatch(success(toolId));
                    dispatch(alertActions.success(response.message));
                    observer.next(response);
                    observer.complete();
                },
                error: err => {
                    console.log(err);
                    dispatch(failure(err.message));
                    dispatch(alertActions.error(err.message));
                    observer.error(err);
                }
            })
        })
    };

    function request(toolId) { return { type: toolConstants.TOOL_DELETE_REQUEST, toolId } }
    function success(toolId) { return { type: toolConstants.TOOL_DELETE_SUCCESS, toolId } }
    function failure(error) { return { type: toolConstants.TOOL_DELETE_FAILURE, error } }

}

function getTool(toolId) {
    return dispatch => {

        return Observable.create((observer) => {

            dispatch(request(toolId));

            toolService.getTool(toolId)
            .subscribe({
                next: response => {
                    dispatch(success(response.data));
                    dispatch(alertActions.success(response.message));
                    observer.next(response);
                    observer.complete();
                },
                error: err => {
                    console.log(err);
                    dispatch(failure(err.message));
                    dispatch(alertActions.error(err.message));
                    observer.error(err);
                }
            })
        })
    };

    function request(tool) { return { type: toolConstants.TOOL_GETTOOL_REQUEST, tool } }
    function success(tool) { return { type: toolConstants.TOOL_GETTOOL_SUCCESS, tool } }
    function failure(error) { return { type: toolConstants.TOOL_GTOOLON_FAILURE, error } }
}

function update(tool) {
    return dispatch => {

        return Observable.create((observer) => {

            dispatch(request({ tool }));

            toolService.updateTool(tool)
            .subscribe({
                next: response => {
                    dispatch(success(tool));
                    dispatch(alertActions.success(response.message));
                    observer.next(response);
                    observer.complete();
                },
                error: err => {
                    console.log(err);
                    dispatch(failure(err.message));
                    dispatch(alertActions.error(err.message));
                    observer.error(err);
                }
            })
        })
    };

    function request(tool) { return { type: toolConstants.TOOL_UPDATE_REQUEST, tool } }
    function success(tool) { return { type: toolConstants.TOOL_UPDATE_SUCCESS, tool } }
    function failure(error) { return { type: toolConstants.TOOL_UPDATE_FAILURE, error } }
}
