import React, { Component } from 'react';
import { connect } from 'react-redux';
import { tap } from 'rxjs/operators';

import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { Growl } from 'primereact/growl';

import { Modal, ModalBody, ModalHeader, ModalFooter } from 'reactstrap';

import { toolActions } from '../../../actions';

class ToolDialog extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isDialogVisible: false,
            isNewElement: false,
            tool: {
                toolId: null,
                toolName: '',
                description: '',
                tcpPose: '',
                payloadMass: 0,
                payloadCog: '',
            },
            tcpXX: 0, tcpYY: 0, tcpZZ: 0,       // tcp pose coordinates
            tcpRX: 0, tcpRY: 0, tcpRZ: 0,       //
            plCogX: 0, plCogY: 0, plCogZ: 0,    // payload center of gravity
        };

        this.user = JSON.parse(localStorage.getItem('user'));
    }

    updateProperty(property, value) {
        let tool = this.state.tool;
        tool[property] = value;
        this.setState({ tool: tool });
    }

    onClickSaveNew() {
        const { dispatch } = this.props;
        const { isNewElement } = this.state;
        const { toolId, toolName, description, payloadMass } = this.state.tool;

        const tcpPose = `[${this.state.tcpXX}, ${this.state.tcpYY}, ${this.state.tcpZZ}, ${this.state.tcpRX}, ${this.state.tcpRY}, ${this.state.tcpRZ}]`;
        const payloadCog = `[${this.state.plCogX}, ${this.state.plCogY}, ${this.state.plCogZ}]`;

        let observable$;

        if (isNewElement) {
            observable$ = dispatch(toolActions.addNew(this.user.id, this.state.tool.toolName, this.state.tool.description,
                tcpPose, this.state.tool.payloadMass, payloadCog))
            .pipe(
                tap({
                    next: response => this.growl.show({ severity: 'success', summary: 'Save tool', detail: response.message }),
                    error: err => this.growl.show({ severity: 'error', summary: 'Save tool', detail: err })
                })
            );
        } else {
            observable$ = dispatch(toolActions.update({
                toolId, toolName, description, tcpPose: tcpPose, payloadMass,
                payloadCog: payloadCog
            }))
            .pipe(
                tap({
                    next: response => this.growl.show({ severity: 'success', summary: 'Edit tool', detail: response.message }),
                    error: err => this.growl.show({ severity: 'error', summary: 'Edit tool', detail: err })
                })
            );
        }

        observable$.subscribe({});

        if(this.props.updateTable) {
            this.props.updateTable();
        }

        this.setState({
            tool: {
                toolId: null,
                toolName: '',
                description: '',
                tcpPose: '',
                payloadMass: 0,
                payloadCog: '',
            },
            tcpXX: 0, tcpYY: 0, tcpZZ: 0,
            tcpRX: 0, tcpRY: 0, tcpRZ: 0,
            plCogX: 0, plCogY: 0, plCogZ: 0,
            gDirX: 0, gDirY: 0, gDirZ: 0,
            isDialogVisible: false
        })
    }

    render() {
        return (
            <React.Fragment>
                <Growl ref={(el) => this.growl = el} style={{marginTop: '50px'}} life={3000} />
                <Modal isOpen={this.state.isDialogVisible}>
                    <ModalHeader>
                        <span>Tool Details</span>
                    </ModalHeader>
                    <ModalBody>
                        <div className="row justify-content-center">
                            {/* TOOL NAME */}
                            <div className="col-11" style={{ padding: '.75em' }}>
                                <label htmlFor={'toolName'}>Tool name</label>
                            </div>
                            <div className="col-11" style={{ padding: '.5em' }}>
                                <InputText className="col-12" id="toolName" onChange={(e) => { this.updateProperty('toolName', e.target.value) }} value={this.state.tool.toolName} />
                            </div>

                            {/* TOOL DESCRIPTION */}
                            <div className="col-11" style={{ padding: '.75em' }}>
                                <label htmlFor={'description'}>Description</label>
                            </div>
                            <div className="col-11" style={{ padding: '.5em' }}>
                                <InputTextarea className="col-12" id="description" label="Description" onChange={(e) => { this.updateProperty('description', e.target.value) }} value={this.state.tool.description} />
                            </div>

                            {/* TCP POSE */}
                            <div className="col-11" style={{ padding: '.75em' }}>
                                <label>Tcp pose</label>
                            </div>
                            <div className="col-11 row justify-content-around" style={{ padding: '.5em' }}>
                                <span className="p-float-label col-4">
                                    <InputText className="col-8" id="tcpXX" keyfilter="num" onChange={(e) => this.setState({ tcpXX: e.target.value })} value={this.state.tcpXX} />
                                    <label className="pl-3" htmlFor="tcpXX">X (m)</label>
                                </span>

                                <span className="p-float-label col-4">
                                    <InputText className="col-8" id="tcpYY" keyfilter="num" onChange={(e) => this.setState({ tcpYY: e.target.value })} value={this.state.tcpYY} />
                                    <label className="pl-3" htmlFor="tcpYY">Y (m)</label>
                                </span>

                                <span className="p-float-label col-4">
                                    <InputText className="col-8" id="tcpZZ" keyfilter="num" onChange={(e) => this.setState({ tcpZZ: e.target.value })} value={this.state.tcpZZ} />
                                    <label className="pl-3" htmlFor="tcpZZ">Z (m)</label>
                                </span>
                            </div>
                            <div className="col-11 row justify-content-around" style={{ padding: '.5em' }}>
                                <span className="p-float-label col-4">
                                    <InputText className="col-8" id="tcpRX" keyfilter="num" onChange={(e) => this.setState({ tcpRX: e.target.value })} value={this.state.tcpRX} />
                                    <label className="pl-3" htmlFor="tcpXX">RX (rad)</label>
                                </span>

                                <span className="p-float-label col-4">
                                    <InputText className="col-8" id="tcpRY" keyfilter="num" onChange={(e) => this.setState({ tcpRY: e.target.value })} value={this.state.tcpRY} />
                                    <label className="pl-3" htmlFor="tcpYY">RY (rad)</label>
                                </span>

                                <span className="p-float-label col-4">
                                    <InputText className="col-8" id="tcpRZ" keyfilter="num" onChange={(e) => this.setState({ tcpRZ: e.target.value })} value={this.state.tcpRZ} />
                                    <label className="pl-3" htmlFor="tcpZZ">RZ (rad)</label>
                                </span>
                            </div>

                            {/* PAYLOAD */}
                            <div className="col-5" style={{ padding: '.75em' }}>
                                <label>Payload</label>
                            </div>
                            <div className="col-6" style={{ padding: '.75em' }}>
                                <label>Payload center of gravity</label>
                            </div>
                            <div className="col-11 row" style={{ padding: '.5em' }}>
                                <span className="p-float-label col-3">
                                    <InputText className="col-10" id="payloadMass" keyfilter="num" onChange={(e) => { this.updateProperty('payloadMass', e.target.value) }} value={this.state.tool.payloadMass} />
                                    <label className="pl-3" htmlFor="payloadMass">Mass (kg)</label>
                                </span>
                                <div className="col-8 offset-1 row">
                                    <span className="p-float-label col-4">
                                        <InputText className="col-10" id="plCogX" keyfilter="num" onChange={(e) => this.setState({ plCogX: e.target.value })} value={this.state.plCogX} />
                                        <label className="pl-3" htmlFor="plCogX">X (m)</label>
                                    </span>
                                    <span className="p-float-label col-4">
                                        <InputText className="col-10" id="plCogY" keyfilter="num" onChange={(e) => this.setState({ plCogY: e.target.value })} value={this.state.plCogY} />
                                        <label className="pl-3" htmlFor="plCogY">Y (m)</label>
                                    </span>
                                    <span className="p-float-label col-4">
                                        <InputText className="col-10" id="plCogZ" keyfilter="num" onChange={(e) => this.setState({ plCogZ: e.target.value })} value={this.state.plCogZ} />
                                        <label className="pl-3" htmlFor="plCogZ">Z (m)</label>
                                    </span>
                                </div>
                            </div>

                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <Button className="common-button" label="Save" icon="pi pi-check" onClick={() => this.onClickSaveNew()} />
                        <Button className="common-button" label="Cancel" icon="pi pi-times" onClick={() => this.setState({ isDialogVisible: false})} />
                    </ModalFooter>

                </Modal>
            </React.Fragment>
        );
    }
}

function mapStateToProps(state) {
    const { alert, authentication } = state;
    const { user } = authentication;
    return {
        alert,
        authentication,
        user
    };
}

const ToolDialogComp = connect(mapStateToProps, null, null, { forwardRef: true })(ToolDialog);
export { ToolDialogComp as ToolDialog };
