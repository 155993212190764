import axios from 'axios';
import jwt_decode from 'jwt-decode';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

export const userService = {
    login,
    logout,
    signup,
    getProjects,
    getRoutines,
    getPoses,
    getTools,
    updatePassword,
    deleteAccount
}

function login(email, password) {
    return Observable.create((observer) => {
        axios.post('/api/login', {
            email: email,
            password: password
        })
        .then(response => {
            observer.next(response.data)
            observer.complete()
        })
        .catch(err => {
            observer.error(err)
        })
    })
    .pipe(
        tap(response => {
            if (response.success !== true) {
                throw response.message;
            }
            saveUser(response.token)
        }),
    )
}

function logout() {
    localStorage.clear();
}

function signup(email, password) {
    return Observable.create((observer) => {
        axios.post('/api/signup', {
            email: email,
            password: password
        })
        .then(response => {
            observer.next(response.data)
            observer.complete()
        })
        .catch(err => {
            observer.error(err)
        })
    })
    .pipe(
        tap(response => {
            if (response.success !== true) {
                throw response.message;
            }
        })
    )
}

function getProjects(userId) {
    return Observable.create((observer) => {
        axios.get('/api/users/'+userId+'/projects')
        .then(response => {
            observer.next(response.data)
            observer.complete()
        })
        .catch(err => {
            observer.error(err)
        })
    })
    .pipe(
        tap(response => {
            if (response.success !== true) {
                throw response.message;
            }
        })
    )
}

function getRoutines(userId) {
    return Observable.create((observer) => {
        axios.get('/api/users/'+userId+'/routines')
        .then(response => {
            observer.next(response.data)
            observer.complete()
        })
        .catch(err => {
            observer.error(err)
        })
    })
    .pipe(
        tap(response => {
            if (response.success !== true) {
                throw response.message;
            }
        })
    )
}

function getPoses(userId) {
    return Observable.create((observer) => {
        axios.get('/api/users/'+userId+'/poses')
        .then(response => {
            observer.next(response.data)
            observer.complete()
        })
        .catch(err => {
            observer.error(err)
        })
    })
    .pipe(
        tap(response => {
            if (response.success !== true) {
                throw response.message;
            }
        })
    )
}

function getTools(userId) {
    return Observable.create((observer) => {
        axios.get('/api/users/'+userId+'/tools')
        .then(response => {
            observer.next(response.data)
            observer.complete()
        })
        .catch(err => {
            observer.error(err)
        })
    })
    .pipe(
        tap(response => {
            if (response.success !== true) {
                throw response.message;
            }
        })
    )
}

function updatePassword(userId, oldPassword, newPassword) {
    return Observable.create((observer) => {
        axios.put('/api/users/'+userId+'/password', {
            oldPassword: oldPassword,
            newPassword: newPassword
        })
        .then(response => {
            observer.next(response.data)
            observer.complete()
        })
        .catch(err => {
            observer.error(err)
        })
    })
    .pipe(
        tap(response => {
            if (response.success !== true) {
                throw response.message;
            }
        })
    )
}

function deleteAccount(userId) {
    return Observable.create((observer) => {
        axios.delete('/api/users/'+userId)
        .then(response => {
            observer.next(response.data)
            observer.complete()
        })
        .catch(err => {
            observer.error(err)
        })
    })
    .pipe(
        tap(response => {
            if (response.success !== true) {
                throw response.message;
            }
        })
    )
}

function saveUser(token) {
    let decoded = jwt_decode(token);
    localStorage.setItem('user', JSON.stringify({
        id: decoded.userId,
        email: decoded.email,
        exp: decoded.exp,
        iat: decoded.iat,
        token: token
    }));
}
