import { combineReducers } from 'redux';

import { authentication } from './authentication.reducer';
import { registration } from './registration.reducer';
import { user } from './user.reducer';
import { alert } from './alert.reducer';
import { project } from './project.reducer';
import { routine } from './routine.reducer';
import { capsule } from './capsule.reducer';
import { pose } from './pose.reducer';
import { tool } from './tool.reducer';

const rootReducer = combineReducers({
  authentication,
  registration,
  user,
  alert,
  project,
  routine,
  capsule,
  pose,
  tool
});

export default rootReducer;
