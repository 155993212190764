import axios from 'axios';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

export const toolService = {
    addNew,
    deleteTool,
    getTool,
    updateTool
}

function addNew(userId, toolName, description, tcpPose, payloadMass, payloadCog) {
    return Observable.create((observer) => {
        axios.post('/api/tool', {
            userId: userId,
            toolName: toolName,
            description: description,
            tcpPose: tcpPose,
            payloadMass: payloadMass,
            payloadCog: payloadCog,
        })
        .then(response => {
            observer.next(response.data)
            observer.complete()
        })
        .catch(err => {
            observer.error(err)
        })
    })
    .pipe(
        tap(response => {
            if (response.success !== true) {
                throw response.message;
            }
        })
    )
}

function deleteTool(toolId) {
    return Observable.create((observer) => {
        axios.delete('/api/tools/'+toolId)
        .then(response => {
            observer.next(response.data)
            observer.complete()
        })
        .catch(err => {
            observer.error(err)
        })
    })
    .pipe(
        tap(response => {
            if (response.success !== true) {
                throw response.message;
            }
        })
    )
}

function getTool(toolId) {
    return Observable.create((observer) => {
        axios.get('/api/tools/'+toolId)
        .then(response => {
            observer.next(response.data)
            observer.complete()
        })
        .catch(err => {
            observer.error(err)
        })
    })
    .pipe(
        tap(response => {
            if (response.success !== true) {
                throw response.message;
            }
        })
    )
}

/**
 * @param {JSON object} tool - properties: toolId, toolName, toolDescription, tcpPose, payloadMass, payloadCog
 */
function updateTool(tool) {
    return Observable.create((observer) => {
        axios.put('/api/tool', tool)
        .then(response => {
            observer.next(response.data)
            observer.complete()
        })
        .catch(err => {
            observer.error(err)
        })
    })
    .pipe(
        tap(response => {
            if (response.success !== true) {
                throw response.message;
            }
        })
    )
}
