export const poseConstants = {
    POSE_ADDNEW_REQUEST: 'POSE_ADDNEW_REQUEST',
    POSE_ADDNEW_SUCCESS: 'POSE_ADDNEW_SUCCESS',
    POSE_ADDNEW_FAILURE: 'POSE_ADDNEW_FAILURE',

    POSE_DELETE_REQUEST: 'POSE_DELETE_REQUEST',
    POSE_DELETE_SUCCESS: 'POSE_DELETE_SUCCESS',
    POSE_DELETE_FAILURE: 'POSE_DELETE_FAILURE',

    POSE_GETPOSE_REQUEST: 'POSE_GETPOSE_REQUEST',
    POSE_GETPOSE_SUCCESS: 'POSE_GETPOSE_SUCCESS',
    POSE_GETPOSE_FAILURE: 'POSE_GETPOSE_FAILURE',

    POSE_UPDATE_REQUEST: 'POSE_UPDATE_REQUEST',
    POSE_UPDATE_SUCCESS: 'POSE_UPDATE_SUCCESS',
    POSE_UPDATE_FAILURE: 'POSE_UPDATE_FAILURE',
};
