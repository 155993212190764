import { poseConstants } from '../constants';

export function pose(state = {}, action) {
    switch (action.type) {
        case poseConstants.POSE_ADDNEW_REQUEST:
            return {
                loading: true
            };
        case poseConstants.POSE_ADDNEW_SUCCESS:
            return {
                pose: action.pose
            };
        case poseConstants.POSE_ADDNEW_FAILURE:
            return {
                error: action.error
            };

        case poseConstants.POSE_DELETE_REQUEST:
            return {
                loading: true,
                pose: action.pose
            };
        case poseConstants.POSE_DELETE_SUCCESS:
            return {
                pose: action.pose
            };
        case poseConstants.POSE_DELETE_FAILURE:
            return {
                error: action.error
            };

        case poseConstants.POSE_GETPOSE_REQUEST:
            return {
                loading: true,
                pose: action.pose
            };
        case poseConstants.POSE_GETPOSE_SUCCESS:
            return {
                pose: action.pose
            };
        case poseConstants.POSE_GETPOSE_FAILURE:
            return {
                error: action.error
            };

        case poseConstants.POSE_UPDATE_REQUEST:
            return {
                loading: true,
                pose: action.pose
            };
        case poseConstants.POSE_UPDATE_SUCCESS:
            return {
                pose: action.pose
            };
        case poseConstants.POSE_UPDATE_FAILURE:
            return {
                error: action.error
            };
        default:
            return state
    }
}
