import React, { Component } from 'react';
import { connect } from 'react-redux';
import { tap } from 'rxjs/operators';

import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { Growl } from 'primereact/growl';

import { Modal, ModalBody, ModalHeader, ModalFooter } from 'reactstrap';

import { projectActions } from '../../../actions';

class ProjectDialog extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isDialogVisible: false,
            isNewElement: false,
            project: {
                projectName: '',
                description: ''
            },
        };

        this.user = JSON.parse(localStorage.getItem('user'));
    }

    updateProperty(property, value) {
        let project = this.state.project;
        project[property] = value;
        this.setState({ project: project });
    }

    onClickSave() {
        const { dispatch } = this.props;
        const { isNewElement } = this.state;
        const { projectId, projectName, description, definition } = this.state.project;

        let observable$;

        if (isNewElement) {
            observable$ = dispatch(projectActions.addNew(this.user.id, projectName, description))
            .pipe(
                tap({
                    next: response => this.growl.show({ severity: 'success', summary: 'New project', detail: response.message }),
                    error: err => this.growl.show({ severity: 'error', summary: 'New project', detail: err })
                })
            );
        } else {
            observable$ = dispatch(projectActions.update({ projectId, projectName, description, definition }))
            .pipe(
                tap({
                    next: response => this.growl.show({ severity: 'success', summary: 'Edit project', detail: response.message }),
                    error: err => this.growl.show({ severity: 'error', summary: 'Edit project', detail: err })
                })
            );
        }

        observable$.subscribe({});

        if(this.props.updateTable) {
            this.props.updateTable();
        }

        this.setState({
            project: {
                projectName: '',
                description: ''
            },
            isDialogVisible: false
        })
    }

    render() {
        return (
            <React.Fragment>
                <Growl ref={(el) => this.growl = el} style={{marginTop: '50px'}} life={3000} />
                <Modal isOpen={this.state.isDialogVisible}>
                    <ModalHeader>
                        <span>Project Details</span>
                    </ModalHeader>
                    <ModalBody>
                        <div className="row justify-content-center">
                            <div className="col-11" style={{ padding: '.75em' }}>
                                <label>Project name</label>
                            </div>
                            <div className="col-11" style={{ padding: '.5em' }}>
                                <InputText className="col-12" id="projectName" onChange={(e) => { this.updateProperty('projectName', e.target.value) }} value={this.state.project.projectName} />
                            </div>
                            <div className="col-11" style={{ padding: '.75em' }}>
                                <label>Project description</label>
                            </div>
                            <div className="col-11" style={{ padding: '.5em' }}>
                                <InputTextarea className="col-12" id="description" rows={5} cols={30} onChange={(e) => { this.updateProperty('description', e.target.value) }} value={this.state.project.description} />
                            </div>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <Button className="common-button" label="Save" icon="pi pi-check" onClick={() => this.onClickSave()} />
                        <Button className="common-button" label="Cancel" icon="pi pi-times" onClick={() => this.setState({ isDialogVisible: false})} />
                    </ModalFooter>

                </Modal>
            </React.Fragment>
        );
    }
}

function mapStateToProps(state) {
    const { alert, authentication } = state;
    const { user } = authentication;
    return {
        alert,
        authentication,
        user
    };
}

const ProjectDialogComp = connect(mapStateToProps, null, null, { forwardRef: true })(ProjectDialog);
export { ProjectDialogComp as ProjectDialog };
