export const projectConstants = {
    PROJECT_ADDNEW_REQUEST: 'PROJECT_ADDNEW_REQUEST',
    PROJECT_ADDNEW_SUCCESS: 'PROJECT_ADDNEW_SUCCESS',
    PROJECT_ADDNEW_FAILURE: 'PROJECT_ADDNEW_FAILURE',

    PROJECT_UPDATE_REQUEST: 'PROJECT_UPDATE_REQUEST',
    PROJECT_UPDATE_SUCCESS: 'PROJECT_UPDATE_SUCCESS',
    PROJECT_UPDATE_FAILURE: 'PROJECT_UPDATE_FAILURE',
    
    PROJECT_DELETE_REQUEST: 'PROJECT_DELETE_REQUEST',
    PROJECT_DELETE_SUCCESS: 'PROJECT_DELETE_SUCCESS',
    PROJECT_DELETE_FAILURE: 'PROJECT_DELETE_FAILURE',

    PROJECT_SELECT: 'PROJECT_SELECT',

    PROJECT_GET_REQUEST: 'PROJECT_GET_REQUEST',
    PROJECT_GET_SUCCESS: 'PROJECT_GET_SUCCESS',
    PROJECT_GET_FAILURE: 'PROJECT_GET_FAILURE',
};
