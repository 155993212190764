import { toolConstants } from '../constants';

export function tool(state = {}, action) {
    switch (action.type) {
        case toolConstants.TOOL_ADDNEW_REQUEST:
            return {
                loading: true
            };
        case toolConstants.TOOL_ADDNEW_SUCCESS:
            return {
                tool: action.tool
            };
        case toolConstants.TOOL_ADDNEW_FAILURE:
            return {
                error: action.error
            };

        case toolConstants.TOOL_DELETE_REQUEST:
            return {
                loading: true,
                tool: action.tool
            };
        case toolConstants.TOOL_DELETE_SUCCESS:
            return {
                tool: action.tool
            };
        case toolConstants.TOOL_DELETE_FAILURE:
            return {
                error: action.error
            };

        case toolConstants.TOOL_GETTOOL_REQUEST:
            return {
                loading: true,
                tool: action.tool
            };
        case toolConstants.TOOL_GETTOOL_SUCCESS:
            return {
                tool: action.tool
            };
        case toolConstants.TOOL_GETTOOL_FAILURE:
            return {
                error: action.error
            };

        case toolConstants.TOOL_UPDATE_REQUEST:
            return {
                loading: true,
                tool: action.tool
            };
        case toolConstants.TOOL_UPDATE_SUCCESS:
            return {
                tool: action.tool
            };
        case toolConstants.TOOL_UPDATE_FAILURE:
            return {
                error: action.error
            };
        default:
            return state
    }
}
