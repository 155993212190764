import { capsuleConstants } from '../constants';

export function capsule(state = {}, action) {
    switch (action.type) {
        case capsuleConstants.CAPSULE_GETSTATE_REQUEST:
            return {
                loading: true
            };
        case capsuleConstants.CAPSULE_GETSTATE_SUCCESS:
            return {
                capsuleState: action
            };
        case capsuleConstants.CAPSULE_GETSTATE_FAILURE:
            return {
                error: action.error
            };

        case capsuleConstants.CAPSULE_SENDPROGRAM_REQUEST:
            return {
                loading: true
            };
        case capsuleConstants.CAPSULE_SENDPROGRAM_SUCCESS:
            return {
                programSent: true
            };
        case capsuleConstants.CAPSULE_SENDPROGRAM_FAILURE:
            return {
                error: action.error
            };
        default:
            return state
    }
}
