export const toolConstants = {
    TOOL_ADDNEW_REQUEST: 'TOOL_ADDNEW_REQUEST',
    TOOL_ADDNEW_SUCCESS: 'TOOL_ADDNEW_SUCCESS',
    TOOL_ADDNEW_FAILURE: 'TOOL_ADDNEW_FAILURE',

    TOOL_DELETE_REQUEST: 'TOOL_DELETE_REQUEST',
    TOOL_DELETE_SUCCESS: 'TOOL_DELETE_SUCCESS',
    TOOL_DELETE_FAILURE: 'TOOL_DELETE_FAILURE',

    TOOL_GETTOOL_REQUEST: 'TOOL_GETTOOL_REQUEST',
    TOOL_GETTOOL_SUCCESS: 'TOOL_GETTOOL_SUCCESS',
    TOOL_GETTOOL_FAILURE: 'TOOL_GETTOOL_FAILURE',

    TOOL_UPDATE_REQUEST: 'TOOL_UPDATE_REQUEST',
    TOOL_UPDATE_SUCCESS: 'TOOL_UPDATE_SUCCESS',
    TOOL_UPDATE_FAILURE: 'TOOL_UPDATE_FAILURE',
};
