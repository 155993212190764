import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Button } from 'primereact/button';
import { Growl } from 'primereact/growl';

import { Modal, ModalBody, ModalHeader, ModalFooter } from 'reactstrap';

import { poseActions } from '../../../actions';

class PoseDeleteDialog extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isDialogVisible: false,
            pose: {},
        };

        this.user = JSON.parse(localStorage.getItem('user'));
    }

    onClickDelete() {
        const { dispatch } = this.props;
        const { poseId } = this.state.pose;

        dispatch(poseActions.deletePose(poseId))
        .subscribe(response => this.growl.show({ severity: 'success', summary: 'Delete pose', detail: response.message }));

        if(this.props.updateTable) {
            this.props.updateTable();
        }

        this.setState({
            pose: {},
            isDialogVisible: false
        });
    }

    render() {
        return (
            <React.Fragment>
                <Growl ref={(el) => this.growl = el} style={{marginTop: '50px'}} life={3000} />
                <Modal isOpen={this.state.isDialogVisible}>
                    <ModalHeader>
                        <span>Delete pose</span>
                    </ModalHeader>
                    <ModalBody>
                        <div style={{ paddingTop: '3em', paddingBottom: '3em', paddingLeft: '0.5em' }}>
                            {this.state.pose.poseName}<span> will be deleted. This action cannot be undone!</span>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <Button className="common-button" label="Confirm" icon="pi pi-check" onClick={() => this.onClickDelete()} />
                        <Button className="common-button" label="Cancel" icon="pi pi-times" onClick={() => this.setState({ isDialogVisible: false})} />
                    </ModalFooter>

                </Modal>
            </React.Fragment>
        );
    }
}

function mapStateToProps(state) {
    const { alert, authentication } = state;
    const { user } = authentication;
    return {
        alert,
        authentication,
        user
    };
}

const PoseDeleteDialogComp = connect(mapStateToProps, null, null, { forwardRef: true })(PoseDeleteDialog);
export { PoseDeleteDialogComp as PoseDeleteDialog };
