import axios from 'axios';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators'

export const capsuleService = {
    getCurrentState,
    sendProgram
}

function getCurrentState(sid) {
    return Observable.create((observer) => {
        axios.get(`/api/capsule/state/${sid}`)
        .then(response => {
            observer.next(response.data)
            observer.complete()
        })
        .catch(err => {
            observer.error(err)
        })
    })
    .pipe(
        tap(response => {
            if(response.success !== true) {
                throw response.message;
            }
        })
    )
}

function sendProgram(sid, code) {
    return Observable.create((observer) => {
        axios.post('/api/capsule/'+sid, {
          robotProgram: code,
        })
        .then(response => {
            observer.next(response.data)
            observer.complete()
        })
        .catch(err => {
            observer.error(err)
        })
    })
    .pipe(
        tap(response => {
            if (response.success !== true) {
                throw response.message;
            }
        })
    )
}
