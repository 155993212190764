import React, { Component } from 'react';


class BlocklyDiv extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <div id="blocklyContainer" {...this.props}>
                <div id="blocklyDiv"></div>

                <div id="helperBlocklyDiv"></div>

                <div id="code-wrapper">
                    <pre id="code"></pre>
                </div>
            </div>
        );
    }
}

export { BlocklyDiv };
