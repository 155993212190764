import { Observable } from 'rxjs';
import { userConstants } from '../constants';
import { userService } from '../services';
import { alertActions } from './';

export const userActions = {
    login,
    logout,
    register,
    getProjects,
    getRoutines,
    getPoses,
    getTools,
    updatePassword,
    deleteAccount
};

function login(email, password) {
    return dispatch => {

        return Observable.create((observer) => {
            
            dispatch(request({ email }));

            userService.login(email, password)
            .subscribe({
                next: response => {
                    dispatch(success(JSON.parse(localStorage.getItem('user'))));
                    observer.next(response);
                    observer.complete();
                },
                error: err => {
                    console.log(err);
                    dispatch(failure(err));
                    dispatch(alertActions.error(err));
                    observer.error(err);
                },
            })
        })
    };

    function request(user) { return { type: userConstants.LOGIN_REQUEST, user } }
    function success(user) { return { type: userConstants.LOGIN_SUCCESS, user } }
    function failure(error) { return { type: userConstants.LOGIN_FAILURE, error } }
}

function logout() {
    userService.logout();
    return { type: userConstants.LOGOUT };
}

function register(email, password) {
    return dispatch => {

        return Observable.create((observer) => {
        
            dispatch(request(email));

            userService.signup(email, password)
            .subscribe({
                next: response => {
                    dispatch(success(email));
                    dispatch(alertActions.success(response.message));
                    observer.next(response);
                    observer.complete();
                },
                error: err => {
                    console.log(err);
                    dispatch(failure(err));
                    dispatch(alertActions.error(err));
                    observer.error(err);
                }
            })
        })
    };

    function request(user) { return { type: userConstants.REGISTER_REQUEST, user } }
    function success(user) { return { type: userConstants.REGISTER_SUCCESS, user } }
    function failure(error) { return { type: userConstants.REGISTER_FAILURE, error } }
}

function getProjects(userId) {
    return dispatch => {

        return Observable.create((observer) => {

            dispatch(request(userId));

            userService.getProjects(userId)
            .subscribe({
                next: response => {
                    dispatch(success(response.data));
                    dispatch(alertActions.success(response.message));
                    observer.next(response);
                    observer.complete();
                },
                error: err => {
                    console.log(err);
                    dispatch(failure(err.message));
                    dispatch(alertActions.error(err.message));
                    observer.error(err);
                }
            })
        })
    };

    function request(user) { return { type: userConstants.GETALLPROJECTS_REQUEST, user } }
    function success(projects) { return { type: userConstants.GETALLPROJECTS_SUCCESS, projects: projects } }
    function failure(error) { return { type: userConstants.GETALLPROJECTS_FAILURE, error } }
}

function getRoutines(userId) {
    return dispatch => {

        return Observable.create((observer) => {

            dispatch(request(userId));

            userService.getRoutines(userId)
            .subscribe({
                next: response => {
                    dispatch(success(response.data));
                    dispatch(alertActions.success(response.message));
                    observer.next(response);
                    observer.complete();
                },
                error: err => {
                    console.log(err);
                    dispatch(failure(err.message));
                    dispatch(alertActions.error(err.message));
                    observer.error(err);
                }
            })
        })
    };

    function request(user) { return { type: userConstants.GETALLROUTINES_REQUEST, user } }
    function success(routines) { return { type: userConstants.GETALLROUTINES_SUCCESS, routines: routines } }
    function failure(error) { return { type: userConstants.GETALLROUTINES_FAILURE, error } }
}

function getPoses(userId) {
  return dispatch => {

      return Observable.create((observer) => {

          dispatch(request(userId));

          userService.getPoses(userId)
          .subscribe({
              next: response => {
                  dispatch(success(response.data));
                  dispatch(alertActions.success(response.message));
                  observer.next(response);
                  observer.complete();
              },
              error: err => {
                  console.log(err);
                  dispatch(failure(err.message));
                  dispatch(alertActions.error(err.message));
                  observer.error(err);
              }
          })
      })
  };

  function request(user) { return { type: userConstants.GETALLPOSES_REQUEST, user } }
  function success(poses) { return { type: userConstants.GETALLPOSES_SUCCESS, poses: poses } }
  function failure(error) { return { type: userConstants.GETALLPOSES_FAILURE, error } }
}

function getTools(userId) {
    return dispatch => {

        return Observable.create((observer) => {

            dispatch(request(userId));

            userService.getTools(userId)
            .subscribe({
                next: response => {
                    dispatch(success(response.data));
                    dispatch(alertActions.success(response.message));
                    observer.next(response);
                    observer.complete();
                },
                error: err => {
                    console.log(err);
                    dispatch(failure(err.message));
                    dispatch(alertActions.error(err.message));
                    observer.error(err);
                }
            })
        })
    };

    function request(user) { return { type: userConstants.GETALLPOSES_REQUEST, user } }
    function success(poses) { return { type: userConstants.GETALLPOSES_SUCCESS, poses: poses } }
    function failure(error) { return { type: userConstants.GETALLPOSES_FAILURE, error } }
}

function updatePassword(userId, oldPassword, newPassword) {
    return dispatch => {
        
        return Observable.create((observer) => {

            dispatch(request(userId));

            userService.updatePassword(userId, oldPassword, newPassword)
            .subscribe({
                next: response => {
                    dispatch(success(userId));
                    dispatch(alertActions.success(response.message));
                    observer.next(response);
                    observer.complete();
                },
                error: err => {
                    console.log(err);
                    dispatch(failure(err.message));
                    dispatch(alertActions.error(err.message));
                    observer.error(err);
                }
            })
        })
    };

    function request(user) { return { type: userConstants.UPDATE_PASSWORD_REQUEST, user } }
    function success(user) { return { type: userConstants.UPDATE_PASSWORD_SUCCESS, user } }
    function failure(error) { return { type: userConstants.UPDATE_PASSWORD_FAILURE, error } }
}

function deleteAccount(userId) {
    return dispatch => {

        return Observable.create((observer) => {

            dispatch(request(userId));

            userService.deleteAccount(userId)
            .subscribe({
                next: response => {
                    dispatch(success(userId));
                    dispatch(alertActions.success(response.message));
                    observer.next(response);
                    observer.complete();
                },
                error: err => {
                    console.log(err);
                    dispatch(failure(err.message));
                    dispatch(alertActions.error(err.message));
                    observer.error(err);
                }
            })
        })
    };

    function request(user) { return { type: userConstants.DELETE_REQUEST, user } }
    function success(user) { return { type: userConstants.DELETE_SUCCESS, user } }
    function failure(error) { return { type: userConstants.DELETE_FAILURE, error } }
}
