import axios from 'axios';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

export const routineService = {
    addNew,
    updateRoutine,
    deleteRoutine,
    getRoutine
}

function addNew(userId, routineName, description) {
    return Observable.create((observer) => {
        axios.post('/api/routine', {
            userId: userId,
            routineName: routineName,
            description: description
        })
        .then(response => {
            observer.next(response.data)
            observer.complete()
        })
        .catch(err => {
            observer.error(err)
        })
    })
    .pipe(
        tap(response => {
            if (response.success !== true) {
                throw response.message;
            }
        })
    )
}

/**
 * @param {JSON object} routine - properties: routineId, routineName, definition, description
 */
function updateRoutine(routine) {
    return Observable.create((observer) => {
        axios.put('/api/routine', routine)
        .then(response => {
            observer.next(response.data)
            observer.complete()
        })
        .catch(err => {
            observer.error(err)
        })
    })
    .pipe(
        tap(response => {
            if (response.success !== true) {
                throw response.message;
            }
        })
    )
}

function deleteRoutine(routineId) {
    return Observable.create((observer) => {
        axios.delete('/api/routines/'+routineId)
        .then(response => {
            observer.next(response.data)
            observer.complete()
        })
        .catch(err => {
            observer.error(err)
        })
    })
    .pipe(
        tap(response => {
            if (response.success !== true) {
                throw response.message;
            }
        })
    )
}

function getRoutine(routineId) {
    return Observable.create((observer) => {
        axios.get('/api/routines/'+routineId)
        .then(response => {
            observer.next(response.data)
            observer.complete()
        })
        .catch(err => {
            observer.error(err)
        })
    })
    .pipe(
        tap(response => {
            if (response.success !== true) {
                throw response.message;
            }
        })
    )
}
